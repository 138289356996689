import React from 'react';

const Footer = () => {
  return (
    <footer>
      <span>All rights reserved © Stevy Sung, 2024</span>
    </footer>
  );
};

export default Footer;
